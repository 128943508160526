import { defineStore } from 'pinia'
const STORE_NAME = 'auth'

const getDefaultToken = () => ({
    token: ''
})

const getToken = () => {
    const token = localStorage.getItem(STORE_NAME)
    return token ? JSON.parse(token) : getDefaultToken()
}
export const useAuthStore = defineStore(STORE_NAME, {
    state: () => ({
        token: getToken(),
    }),
    actions: {
        updateToken(token: any) {
            this.token = {
                token
            }
            localStorage.setItem(STORE_NAME, JSON.stringify(this.token))
        },
    },
})