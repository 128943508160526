import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'users' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'users' },
    children: [
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'profile',
        path: 'profile/:id',
        component: () => import('../pages/profile/ProfileView.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'add_profile',
        path: 'profile/new',
        component: () => import('../pages/profile/ProfileAdd.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'deployment',
        path: 'deployment',
        component: () => import('../pages/deployment/Deployment.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'demographics',
        path: 'demographics',
        component: () => import('../pages/demographics/Demographics.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'finance',
        path: 'finance',
        component: () => import('../pages/finance/Finance.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'feedback',
        path: 'feedback',
        component: () => import('../pages/feedback/Feedback.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'edit_profile',
        path: 'profile/:id/edit',
        component: () => import('../pages/profile/ProfileEdit.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      // {
      //   name: 'settings',
      //   path: 'settings',
      //   component: () => import('../pages/settings/Settings.vue'),
      // },
      // {
      //   name: 'preferences',
      //   path: 'preferences',
      //   component: () => import('../pages/preferences/Preferences.vue'),
      // },
      

      // {
      //   name: 'projects',
      //   path: 'projects',
      //   component: () => import('../pages/projects/ProjectsPage.vue'),
      // },
      // {
      //   name: 'payments',
      //   path: '/payments',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'payment-methods',
      //       path: 'payment-methods',
      //       component: () => import('../pages/payments/PaymentsPage.vue'),
      //     },
      //     {
      //       name: 'billing',
      //       path: 'billing',
      //       component: () => import('../pages/billing/BillingPage.vue'),
      //     },
      //     {
      //       name: 'pricing-plans',
      //       path: 'pricing-plans',
      //       component: () => import('../pages/pricing-plans/PricingPlans.vue'),
      //     },
      //   ],
      // },
      // {
      //   name: 'faq',
      //   path: '/faq',
      //   component: () => import('../pages/faq/FaqPage.vue'),
      // },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      // {
      //   name: 'signup',
      //   path: 'signup',
      //   component: () => import('../pages/auth/Signup.vue'),
      // },
      // {
      //   name: 'recover-password',
      //   path: 'recover-password',
      //   component: () => import('../pages/auth/RecoverPassword.vue'),
      // },
      // {
      //   name: 'recover-password-email',
      //   path: 'recover-password-email',
      //   component: () => import('../pages/auth/CheckTheEmail.vue'),
      // },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})
router.beforeEach((to, from, next) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  const token = auth?.token;
  console.log(auth?.token);
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (token) {
      // User is authenticated
      next();
    } else {
      // User is not authenticated; redirect to login
      next('/auth/login');
    }
  } else {
    // Allow access to non-protected routes
    next();
  }
});
export default router
